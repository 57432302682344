
import { Component, Vue } from 'vue-property-decorator';
import { AdminCauseCategories, AdminCause } from './components';
import { AdminService } from '@/services';
import { ICauseCategorySettings, ICauseSettings } from '@/types/cause.types';

@Component({ components: { AdminCauseCategories, AdminCause } })
export default class AdminCauses extends Vue {
  causesCategories: ICauseCategorySettings[] = [];
  causes: ICauseSettings[] = [];

  async getCausesCategoriesAndCauses(): Promise<void> {
    try {
      this.causesCategories = await AdminService.fetchCauseCategories();
      this.causes = await AdminService.fetchCauses();
    } catch (e: any) {
      this.$notifyError('Something went wrong');
    }
  }

  async created(): Promise<void> {
    await this.getCausesCategoriesAndCauses();
  }
}
